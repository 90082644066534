<template>
    <painel titulo="SENAC - Usuários" icone="pi pi-users" :refreshFunction="atualizar" v-if="mostrarListagem">
        <tabela :data="usuarios" dataKey="usuarioId" :globalFilterFields="['nome', 'login']" stateKey="dt-senac-usuarios">
            <template #botoes>
                <btn-inserir v-if="$temAcessoView('SENAC-USUARIOS-01')"></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :usuario="slotProps.data" v-if="$temAcessoView('SENAC-USUARIOS-02')"></btn-detalhar>
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="login" header="Login" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.login }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import UsuariosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            usuarios: null,
        };
    },

    methods: {
        obterUsuarios() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterTodos().then((response) => {
                if (response?.success) {
                    this.usuarios = response.data;
                } else {
                    this.usuarios = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterUsuarios();
        },
    },

    mounted() {
        this.obterUsuarios();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Senac_Usuarios';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Senac_Usuarios') {
                if (this.$store.getters.atualizar) {
                    this.obterUsuarios();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>
