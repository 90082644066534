import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SENAC_PORT}${process.env.VUE_APP_API_PATH}`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/senac/usuarios`);
    },

    obterUsuariosDisponiveis() {
        return axiosJwt.get(`${api}/senac/usuarios/disponiveis`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/senac/usuarios/${id}`);
    },

    inserir(usuario) {
        return axiosJwt.post(`${api}/senac/usuarios/inserir`, usuario);
    },

    obterProjetosAcoes() {
        return axiosJwt.get(`${api}/senac/usuarios/projetosacoes`);
    },

    inserirProjetoAcao(usuarioProjetoAcao) {
        return axiosJwt.post(`${api}/senac/usuarios/${usuarioProjetoAcao.usuarioId}/projetoacao/inserir`, usuarioProjetoAcao);
    },

    excluirProjetoAcao(usuarioProjetoAcao) {
        return axiosJwt.post(`${api}/senac/usuarios/${usuarioProjetoAcao.usuarioId}/projetoacao/excluir`, usuarioProjetoAcao);
    },

    ativar(usuarioId) {
        return axiosJwt.patch(`${api}/senac/usuarios/${usuarioId}/ativar`);
    },

    desativar(usuarioId) {
        return axiosJwt.patch(`${api}/senac/usuarios/${usuarioId}/desativar`);
    },
};
